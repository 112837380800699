(function($) {
    
    $(document).ready(function(){
        
          $('.carousel').slick({
          centerMode: true,
          centerPadding: '0px',
          slidesToScroll: 1,
          slidesToShow: 3,
          initialSlide: 1,
          //variableWidth: true,
          responsive: [
            {
              breakpoint: 768,
              settings: {
                arrows: false,
                centerMode: true,
                centerPadding: '0px',
                slidesToShow: 1,
                slidesToScroll: 1
              }
            }
          ]
        });
    });
     
    $(window).on("load", function() {
          
        //Carousel image ratio base
        /*var image = $(".image.force-fixed-landscape-ratio");
        image.each(function( index ) {
        var image = $(this);
        var image_img = image.find('img');
        if (image_img.length) {

        var ratio = 0;
        if (image_img.width()>0 && image_img.height()>0)
            ratio = image_img.width() / image_img.height();

            //cut image bottom
            //alert(image.outerHeight(true)-image.innerHeight());
            var refw=image.width();
            image.height((refw / 1.5));
        }
        });*/
    
        //Media image ratio base
        var image = $(".image.preserve-ratio");
        image.each(function( index ) {
            var image = $(this);
            var image_img = image.find('img');
            if (image_img.length) {

                var ratio = 0;
                if (image_img.width()>0 && image_img.height()>0)
                    ratio = image_img.width() / image_img.height();
                
               if (ratio<1 && ratio>0) {
                    image.addClass('portrait');
                    
                    //dynamic crop
                    //image_img.css('margin-top', ((image.height() -  image_img.height())/2));
                }
                else if (ratio>1) {
                    image.addClass('landscape');
                    //dynamic crop
                    image_img.css('margin-left', ((image.width() -  image_img.width())/2));
                }
                else if (ratio==1) {
                    image.addClass('square');
                    image_img.css('margin-top', ((image.height() -  image_img.height())/2));
                }
                else 
                    image.addClass('ratio_unknown');

                image.css('opacity', '1');
            }
        });
        
        //Members image square ratio base
        var image = $(".image.square-ratio");
        image.each(function( index ) {
            var image = $(this);
            var image_img = image.find('img');
            if (image_img.length) {

                var ratio = 0;
                if (image_img.width()>0 && image_img.height()>0)
                    ratio = image_img.width() / image_img.height();
                
               if (ratio<1 && ratio>0) {
                    image.addClass('portrait');
                    //dynamic crop
                    image_img.css('margin-top', ((image.height() -  image_img.height())/2));
                }
                else if (ratio>1) {
                    image.addClass('landscape');
                    //dynamic crop
                    image_img.css('margin-left', ((image.width() -  image_img.width())/2));
                }
                else if (ratio==1) {
                    image.addClass('square');
                    //image_img.css('margin-top', ((image.height() -  image_img.height())/2));
                }
                else 
                    image.addClass('ratio_unknown');

                image.css('opacity', '1');
            }
        });
        
        //Quiz management
        var quiz = $('.quiz');
        if (quiz.length) {
   
            //Answer
            quiz.find( "#js-quiz-submit" ).click(function(event) {
                 event.preventDefault();
                
                //Get post id
                var postid = quiz.find('.quiz-data-postid').html();

                //Get correct answer
                var result=quiz.find('.quiz-data-result').html();

                //Get submitted answer
                var answer= quiz.find('input[name=question]:checked').val(); 
                
                var iscorrect=false;
                if (result==answer) iscorrect=true;
                
                //Display answer and stop game 
                if (iscorrect) quiz.find('.quiz-answer-correct').show();
                else quiz.find('.quiz-answer-incorrect').show();
                quiz.find('.quiz-answer-more').show();
           
                quiz.find('.quiz-question-propositions').hide();
                $(this).hide();  
                
                //Allow share on social networks
                quiz.find('.quiz-share').show();

                //Save answer to server
                $.ajax({
                    method: "POST",
                    url:  custom_params.ajax_url,
                    data: { 
                        'action': 'quiz_saveanswer',
                        'postid': postid
                    },
                    success:function(data)
                    {
                        console.log('Save quiz submit for post ' + postid + ' returned ' + data );
                    },
                    error: function(errorThrown)
                    {
                        console.log(errorThrown);
                    }               
                });   
            });
            
            //Share
            quiz.find("[id^=js-quiz-share-]").click(function(event) {
                /*Save sharing to server*/

                //Get post id
                var postid = quiz.find('.quiz-data-postid').html();

                $.ajax({
                    method: "POST",
                    url:  custom_params.ajax_url,
                    data: { 
                        'action': 'quiz_savesharing',
                        'postid': postid
                    },
                    success:function(data)
                    {
                        console.log('Save quiz share for post ' + postid + ' returned ' + data);
                    },
                    error: function(errorThrown)
                    {
                        console.log(errorThrown);
                    }
                      
                });   
            });
        }

    });
    
    
})(jQuery); // Fully reference jQuery after this point.